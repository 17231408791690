import "./Information.css"

const Information = () => {
    return (
        <div className="info__container">
            {/* <div className="info__information-container info__text">
                <div className="video" style={{ backgroundImage: `url(${require("../../assets/Theke.MP4")})` }}> </div>
                <div>
                    <p>Wir haben jetzt wieder 32 Eissorten für Sie in unserem Eiscafé</p>
                </div>
            </div> */}
            <div className="info__information-container info__text" >
                {/* <div className="video" style={{ backgroundImage: `url(${require("../../assets/xmas.mp4")})` }}> </div> */}
                <p className="info__header">Liebe Gäste,</p>
                <p>wir möchten uns herzlich bei Ihnen für das tolle Jahr 2024 bedanken! Ihre Treue und Unterstützung bedeuten uns unglaublich viel.</p>
                <p><span className="bold-text">Wir machen vom 23. Dezember bis zum 9. Januar eine kleine Winterpause</span>. Ab dem 10. Januar sind wir wie gewohnt wieder für Sie da und freuen uns darauf, Sie zu verwöhnen.</p>
                <p>Wir wünschen Ihnen und Ihren Liebsten wunderschöne Weihnachten und einen guten Rutsch ins neue Jahr!</p>
                <p>Familie Vitanza</p>
            </div>
        </div>
    );
}

export default Information;